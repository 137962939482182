<template>
   <div class="list">
      <div class="sublist" v-for="component in componentsList" :key="component.name">
         <h2>{{ component.name }}</h2>
         <component :is="componets[component.name]">adf</component>
         <table>
            <thead>
               <tr>
                  <th>Prop name</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Default</th>
                  <th>Note</th>
               </tr>
            </thead>
            <tbody>
               <tr v-for="key in Object.keys(component.props)" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ component.props[key].type }}</td>
                  <td>{{ component.props[key].required ? component.props[key].required : 'false' }}</td>
                  <td>
                     {{ component.props[key].default !== 'undefined' ? component.props[key].default : 'no-default' }}
                  </td>
                  <td>{{ component.props[key].note ? component.props[key].note : 'Has no note' }}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</template>

<script>
import { SWButton, SWNavButton, SWInput, SWSwetcher, SWCheckbox } from 'sw-ui-kit';

import propDoc from 'propdoc';

export default {
   components: { propDoc },
   // inject: ['snackbar'],
   data() {
      return {
         password: '',
         switcher: false,
         componets: {
            SWButton: SWButton,
            SWNavButton: SWNavButton,
            SWInput: SWInput,
            SWCheckbox: SWCheckbox,
            SWSwetcher: SWSwetcher,
         },
      };
   },
   computed: {
      componentsList() {
         return [
            propDoc.getDoc(SWNavButton),
            propDoc.getDoc(SWButton),
            propDoc.getDoc(SWInput),
            propDoc.getDoc(SWCheckbox),
            propDoc.getDoc(SWSwetcher),
         ];
      },
   },
};
</script>

<style lang="sass" scoped>
.list
   display: grid
   place-items: baseline
   row-gap: 20px
   background-color: var(--surface)

th
   padding: 20px
   margin: 20px
   border:1px solid grey



td
   border:1px solid grey
   padding: 20px

   text-align: center
.sublist
   width: 100%
   display: grid
   margin: 40px

   place-items: baseline
   row-gap: 10px
   border-bottom: 1px solid #000
   padding-bottom: 20px
</style>
